import { Link } from 'react-router-dom';
import './App.css';
import companyLogo from './CompanyLogo.png';
import snackCraze from './Capture.PNG';

function Home()
{
    return (
        <div className='container'>
            <div className='topbar'>
                <div className='leftcontent'>
                    <Link className='navcontainer' to='/'>
                        <div className='logo_and_title'>
                            <img src={companyLogo} alt='Snowvision Studio' width={120}></img>
                            <p className='title'>Snowvision</p>
                        </div>
                    </Link>
                    <a href="javascript:void(0);" className="hamburger" onClick={myFunction}>
                        <i class="fa fa-bars"></i>
                    </a>
                </div>
                <div id='rightcontentDiv'>
                    <div className='navcontainer' id='rightcontent'>
                        <Link className='navcontainer' id='rightcontent' to='/'>
                            <button className='navbutton focused'>Home</button>
                        </Link>
                        <Link className='navcontainer' id='rightcontent' to='/Apps'>
                            <button className='navbutton'>Apps</button>
                        </Link>
                        <Link className='navcontainer' id='rightcontent' to='/Games'>
                            <button className='navbutton'>Games</button>
                        </Link>
                        <Link className='navcontainer' id='rightcontent' to='/Contact'>
                            <button className='navbutton'>Contact Us</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '20px' }}>
                <h1 className='gamesheader'>About us</h1>
                <a href='https://play.google.com/store/apps/details?id=com.Snowvision.SnackCraze'>
                    <img src={snackCraze} alt='Snack Craze' width={120} />
                </a>
                <p>A small development studio based in Ontario, Canada. <br /> Our goal is to plan, execute, and develop mobile applications, games, and projects that we brainstorm and see fit to test ourselves. <br />
                    Feel free to check out our portfolio and reach out to us if you have any questions.
                </p>
            </div>
            <div className='footer'>
                <p className='footertext'>&copy; {new Date().getFullYear()}<br />Snowvision Studio<br /><Link className='a footertext' to='/PrivacyPolicy'>Privacy Policy</Link></p>
            </div>
        </div>
    );
}

export function myFunction()
{
    var x = document.getElementById('rightcontentDiv');
    if (x.style.display === "block")
    {
        console.log('hello')
        x.style.display = "none";
    } else
    {
        x.style.display = "block";
    }
}
export default Home;