import { Link } from 'react-router-dom';
import './App.css';
import companyLogo from './CompanyLogo.png';
import { myFunction } from './Home.js'
import Upcoming from './Apps/Upcoming.png'

function Apps()
{
    return (
        <div className='container'>
            <div className='topbar'>
                <div className='leftcontent'>
                    <Link className='navcontainer' to='/'>
                        <div className='logo_and_title'>
                            <img src={companyLogo} alt='Snowvision Studio' width={120}></img>
                            <p className='title'>Snowvision</p>
                        </div>
                    </Link>
                    <a href="javascript:void(0);" className="hamburger" onClick={myFunction}>
                        <i class="fa fa-bars"></i>
                    </a>
                </div>
                <div id='rightcontentDiv'>
                    <div className='navcontainer' id='rightcontent'>
                        <Link className='navcontainer' id='rightcontent' to='/'>
                            <button className='navbutton'>Home</button>
                        </Link>
                        <Link className='navcontainer' id='rightcontent' to='/Apps'>
                            <button className='navbutton focused'>Apps</button>
                        </Link>
                        <Link className='navcontainer' id='rightcontent' to='/Games'>
                            <button className='navbutton'>Games</button>
                        </Link>
                        <Link className='navcontainer' id='rightcontent' to='/Contact'>
                            <button className='navbutton'>Contact Us</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='gamescontainer'>
                <h1 className='gamesheader'>Applications</h1>
                <div className='gamescontent'>
                    <div className='gameplaceholder' style={{ borderStyle: 'solid', borderColor: 'lightGrey' }}>
                        <a href="" className='gamelink'>
                            <img src={Upcoming} alt='' className='gameimg'></img>
                            <div>Upcoming Project</div>
                        </a>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <p className='footertext'>&copy; {new Date().getFullYear()}<br />Snowvision Studio<br /><Link className='a footertext' to='/PrivacyPolicy'>Privacy Policy</Link></p>
            </div>
        </div >
    );
}

export default Apps;