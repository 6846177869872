import { Link } from 'react-router-dom';
import './App.css';
import companyLogo from './CompanyLogo.png';

function PrivacyPolicy()
{
    return (
        <div className='container'>
            <div className='topbar'>
                <Link className='navcontainer' to='/'>
                    <div className='logo_and_title'>
                        <img src={companyLogo} alt='Snowvision Studio' width={120}></img>
                        <p className='title'>Snowvision</p>
                    </div>
                </Link>
                <div className='navcontainer'>
                    <Link className='navcontainer' to='/'>
                        <button className='navbutton'>Home</button>
                    </Link>
                    <Link className='navcontainer' to='/Apps'>
                        <button className='navbutton'>Apps</button>
                    </Link>
                    <Link className='navcontainer' to='/Games'>
                        <button className='navbutton'>Games</button>
                    </Link>
                    <Link className='navcontainer' to='/Contact'>
                        <button className='navbutton'>Contact Us</button>
                    </Link>
                </div>
            </div>
            <div style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>
                <Link to='/PrivacyPolicySnackCraze'>Snack Craze</Link>
                <Link to='/PrivacyPolicyToiletPaperIdleClicker'>Toilet Paper Idle Clicker</Link>
                <Link to='/PrivacyPolicyIdlePunk'>IdlePunk</Link>
            </div>
            <div className='footer'>
                <p className='footertext'>&copy; {new Date().getFullYear()}<br />Snowvision Studio<br /><Link className='a footertext' to='/PrivacyPolicy'>Privacy Policy</Link></p>
            </div>
        </div >
    );
}
export default PrivacyPolicy;