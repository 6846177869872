import { Link } from 'react-router-dom';
import './App.css';
import companyLogo from './CompanyLogo.png';
import { myFunction } from './Home.js'
import React, { useState } from 'react'
import emailjs from 'emailjs-com'

function Contact()
{
    return (
        <div className='container'>
            <div className='topbar'>
                <div className='leftcontent'>
                    <Link className='navcontainer' to='/'>
                        <div className='logo_and_title'>
                            <img src={companyLogo} alt='Snowvision Studio' width={120}></img>
                            <p className='title'>Snowvision</p>
                        </div>
                    </Link>
                    <a href="javascript:void(0);" className="hamburger" onClick={myFunction}>
                        <i class="fa fa-bars"></i>
                    </a>
                </div>
                <div id='rightcontentDiv'>
                    <div className='navcontainer' id='rightcontent'>
                        <Link className='navcontainer' id='rightcontent' to='/'>
                            <button className='navbutton'>Home</button>
                        </Link>
                        <Link className='navcontainer' id='rightcontent' to='/Apps'>
                            <button className='navbutton'>Apps</button>
                        </Link>
                        <Link className='navcontainer' id='rightcontent' to='/Games'>
                            <button className='navbutton'>Games</button>
                        </Link>
                        <Link className='navcontainer' id='rightcontent' to='/Contact'>
                            <button className='navbutton focused'>Contact Us</button>
                        </Link>
                    </div>
                </div>
            </div>
            {Form()}
            <div className='footer'>
                <p className='footertext'>&copy; {new Date().getFullYear()}<br />Snowvision Studio<br /><Link className='a footertext' to='/PrivacyPolicy'>Privacy Policy</Link></p>
            </div>
        </div >
    );
}

const Form = () => 
{
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (event) =>
    {
        event.preventDefault();
        Submit(templateParams)
    }

    var templateParams = {
        name: name,
        email: email,
        message: message
    }


    return (
        <form onSubmit={handleSubmit} className='formcontainer'>
            <label className='form'>
                <span style={{ fontWeight: '600', fontSize: '20px' }}>Your Details</span>
                <span>Let us know how to get back to you.</span>
                <div className='topformContainer'>
                    <div className='dividers'>
                        <span style={{ fontWeight: 'bold' }}>Name:</span>
                        <input type='text' placeholder='' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className='dividers'>
                        <span style={{ fontWeight: 'bold' }}>Email:</span>
                        <input type='text' placeholder='' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                <span style={{ fontWeight: '600', fontSize: '20px' }}>How can we help?</span>
                <span>Feel free to ask a question or simply leave a comment.</span>
                <div className='dividers'>
                    <span style={{ fontWeight: 'bold' }}>Message:</span>
                    <textarea className='m' type='text' rows='7' placeholder='' value={message} onChange={(e) => setMessage(e.target.value)} />
                    <input type='submit' className='submitform'></input>
                </div>
            </label>
        </form>
    )
}

function Submit(templateParams)
{

    emailjs.send('service_p3d2so6', 'template_a2qmodh', templateParams, 'x1YQNdE6fiBf0p7Au')
        .then(function (response)
        {
            console.log('SUCCESS!', response.status, response.text);
        }, function (error)
        {
            console.log('FAILED...', error);
        });
    alert('Thank you! Your message has been sent.')
}


export default Contact;



