import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import Apps from './Apps';
import Games from './Games';
import Contact from './Contact';
import PrivacyPolicy from './PrivacyPolicy';
import PrivacyPolicyToiletPaperIdleClicker from './PrivacyPolicies/PrivacyPolicyToiletPaperIdleClicker';
import PrivacyPolicySnackCraze from './PrivacyPolicies/PrivacyPolicySnackCraze';
import PrivacyPolicyIdlePunk from './PrivacyPolicies/PrivacyPolicyIdlePunk';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Apps" element={<Apps />} />
        <Route path="/Games" element={<Games />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/PrivacyPolicyToiletPaperIdleClicker" element={<PrivacyPolicyToiletPaperIdleClicker />} />
        <Route path="/PrivacyPolicySnackCraze" element={<PrivacyPolicySnackCraze />} />
        <Route path="/PrivacyPolicyIdlePunk" element={<PrivacyPolicyIdlePunk />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
