import { Link } from 'react-router-dom';
import './App.css';
import companyLogo from './CompanyLogo.png';
import SnackCraze from './Games/SnackCraze.png'
import IdlePunk from './Games/IdlePunk.png'
import ToiletPaperClicker from './Games/ToiletPaperClicker.png'
import { myFunction } from './Home.js'

function Games()
{
    return (
        <div className='container'>
            <div className='topbar'>
                <div className='leftcontent'>
                    <Link className='navcontainer' to='/'>
                        <div className='logo_and_title'>
                            <img src={companyLogo} alt='Snowvision Studio' width={120}></img>
                            <p className='title'>Snowvision</p>
                        </div>
                    </Link>
                    <a href="javascript:void(0);" className="hamburger" onClick={myFunction}>
                        <i class="fa fa-bars"></i>
                    </a>
                </div>
                <div id='rightcontentDiv'>
                    <div className='navcontainer' id='rightcontent'>
                        <Link className='navcontainer' id='rightcontent' to='/'>
                            <button className='navbutton'>Home</button>
                        </Link>
                        <Link className='navcontainer' id='rightcontent' to='/Apps'>
                            <button className='navbutton'>Apps</button>
                        </Link>
                        <Link className='navcontainer' id='rightcontent' to='/Games'>
                            <button className='navbutton focused'>Games</button>
                        </Link>
                        <Link className='navcontainer' id='rightcontent' to='/Contact'>
                            <button className='navbutton'>Contact Us</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className='gamescontainer'>
                <h1 className='gamesheader'>Games</h1>
                <div className='gamescontent'>
                    <div className='gameplaceholder'>
                        <a href="https://play.google.com/store/apps/details?id=com.Snowvision.SnackCraze" className='gamelink'>
                            <img src={SnackCraze} alt='Snack Craze' className='gameimg'></img>
                            <div>Snack Craze</div>
                        </a>
                    </div>
                    <div className='gameplaceholder'>
                        <a href='https://play.google.com/store/apps/details?id=com.Snowvision.Idlepunk' className='gamelink'>
                            <img src={IdlePunk} alt='IdlePunk - Idle Clicker' className='gameimg'></img>
                            <div>IdlePunk</div>
                        </a>
                    </div>
                    <div className='gameplaceholder'>
                        <a href='https://play.google.com/store/apps/details?id=com.Snowvision.ToiletPaperClicker' className='gamelink'>
                            <img src={ToiletPaperClicker} alt='Toilet Paper Idle Clicker' className='gameimg'></img>
                            <div>Toilet Paper Idle Clicker</div>
                        </a>
                    </div>
                </div>
            </div>
            <div className='footer'>
                <p className='footertext'>&copy; {new Date().getFullYear()}<br />Snowvision Studio<br /><Link className='a footertext' to='/PrivacyPolicy'>Privacy Policy</Link></p>
            </div>
        </div>
    );
}

export default Games;